import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_pagination = _resolveComponent("list-pagination")
  const _component_firmware_Dialog = _resolveComponent("firmware-Dialog")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_list_pagination, {
      headers: _ctx.headers,
      requestData: _ctx.listRequestData,
      showDefaultAction: ['info'],
      update: _ctx.updateToList,
      showCheck: false,
      onInfo: _ctx.getFirmwareInfo
    }, {
      LogSlot: _withCtx((data) => [
        _createVNode("span", null, _toDisplayString(data.scope.Log[0]), 1)
      ]),
      _: 1
    }, 8, ["headers", "requestData", "update", "onInfo"]),
    (_ctx.isShowFirewareDialog)
      ? (_openBlock(), _createBlock(_component_firmware_Dialog, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowFirewareDialog=false)),
          initForm: _ctx.formData
        }, null, 8, ["initForm"]))
      : _createCommentVNode("", true)
  ]))
}