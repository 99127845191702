import { ref, Ref } from 'vue';
import { ListHeaderItem } from '@/components/common/list';
import { FirmwareForm } from '@/components/view/installer/community/firmware-dialog';

const isShowFirewareDialog = ref(false);
const initData = () => {
    const headers: Array<ListHeaderItem> = [{
        name: 'Version',
        label: WordList.TabelUpdateBoxVersion
    }, {
        name: 'Model',
        label: WordList.TabelVersionModel
    }, {
        name: 'Log',
        label: WordList.TabelVersionLog,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];
    return {
        headers
    };
};
const formData: Ref<FirmwareForm> = ref({
    Log: '',
    Version: '',
    Model: ''
});
type formType = 'Version' | 'Model' | 'Log';
function getFirmwareInfo(value: FirmwareForm) {
    const firewareInfo = JSON.parse(JSON.stringify(value));
    Object.keys(formData.value).forEach((item) => {
        formData.value[item as formType] = firewareInfo[item as formType];
    });
    isShowFirewareDialog.value = true;
}

export {
    initData,
    isShowFirewareDialog,
    formData,
    getFirmwareInfo
};