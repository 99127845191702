
import { defineComponent, Ref, ref } from 'vue';
import {
    listPagination, RequestData
} from '@/components/common/list';
import firmwareDialog from '@/components/view/installer/community/firmware-dialog';
import {
    initData, isShowFirewareDialog, formData, getFirmwareInfo
} from './util';

export default defineComponent({
    components: {
        listPagination,
        firmwareDialog
    },
    setup() {
        const {
            headers
        } = initData();
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'v3/web/single/version/getVersionList',
            param: {
            }
        });
        return {
            headers,
            updateToList,
            listRequestData,
            isShowFirewareDialog,
            getFirmwareInfo,
            formData
        };
    }
});
